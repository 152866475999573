import React, { useCallback, useEffect, useState } from "react";
import { careHomesMenu, careHomesList } from "../../../data";
import style from "../../../styles/CareHomes.module.scss";
import images from "../../../assets/images";

const CareHomes = () => {
  const [active, setActive] = useState(0);
  const [list, setlist] = useState(0);

  const toggleActive = useCallback(
    (step) => {
      setActive(step);
    },
    [active]
  );

  useEffect(() => {
    setlist(careHomesList[active]);
  }, [active]);

  return (
    <section className={style.chWrap}>
      <div className={style.bannerWave}></div>
      <div className={`${style.chContainer} container-xl`}>
        <div className={style.content}>
          <div className={style.menu}>
            {careHomesMenu.map((menu, index) => (
              <div className={style.menuItem} key={index}>
                <p
                  onClick={() => {
                    toggleActive(index);
                  }}
                  className={
                    active === index
                      ? `${style.title} ${style.titleActive}`
                      : style.title
                  }
                >
                  {menu}
                </p>
              </div>
            ))}
          </div>
          <div className={style.list}>
            {list.length > 0 &&
              list.map((home, key) => (
                <div className={style.listItem} key={key}>
                  <div className={style.header}>
                    <img
                      src={images.home.src}
                      alt={`${key}-home-img`}
                      className={style.image}
                    />
                  </div>
                  <div className={style.content}>
                    <h3>{home}</h3>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default CareHomes;
